import type {
  TimesheetResetBody,
  TimesheetResetResponse,
  TimesheetSendToClientBody,
  TimesheetSendToClientResponse,
} from "@asap/shared/src/schemas/timesheet";
import type {
  CreateJobOfferResponse,
  DeleteJobOfferResponse,
  GetJobPostingResponse,
  JobPostingQuery,
  UpdateJobOfferResponse,
} from "@asap/shared/src/types/job-posting";
import type { NavButtonCount } from "@asap/shared/src/types/navbar";
import type {
  QualificationTrainingRequestApproveQuery,
  QualificationTrainingRequestApproveResponse,
} from "@asap/shared/src/types/qualification-training-request";

import type { TalentContractToSignNotificationBody } from "@asap/shared/src/schemas/contract";
import type { SearchByMissionNameBody } from "@asap/shared/src/schemas/search";
import type {
  DocumentSenderContactBody,
  DocumentSenderTalentBody,
  HtmlEmailSenderBody,
  MedispaceEmailSenderBody,
  SmsSenderBody,
} from "@asap/shared/src/supabase/use-cases/sender-handler/schema";
import type { LoggerService } from "@asap/shared/src/types/logger-service.ts";
import type {
  TeamMemberAbsenceBody,
  TeamMemberAbsenceResponse,
  TeamMemberLinkSlackResponse,
  TeamMemberOffboardingBody,
  TeamMemberOffboardingStatusQuery,
  TeamMemberOffboardingStatusResponse,
  TeamMemberRevertAbsenceBody,
} from "@asap/shared/src/types/team-member";
import type { ErrorSerialized } from "@asap/shared/src/utils/error";
import { getErrorMessageForClient, SharedError } from "@asap/shared/src/utils/error.ts";
import type {
  Body as AnalyticsTalentIdentifyBody,
  Response as AnalyticsTalentIdentifyResponse,
} from "../schemas/analytics/talent/identify";
import type {
  Body as AnalyticsTalentTrackEventBody,
  Response as AnalyticsTalentTrackEventResponse,
} from "../schemas/analytics/talent/track-event";
import type {
  Query as CompanyAutocompleteQuery,
  Response as CompanyAutocompleteResponse,
} from "../schemas/company/autocomplete";
import type { ContractCoefficientsListParams, ContractCoefficientsResult } from "../schemas/contract-coefficients/list";
import type { FeatureFlagQuery, FeatureFlagResponse } from "../schemas/feature-flag";
import type {
  FileOrphanResponseType,
  FileParseParamsType,
  FileParseResponseType,
  FileParseRibIbanResponseType,
} from "../schemas/file.schema";
import type { HiringPoolTableQueryParams, HiringPoolTableRow } from "../schemas/hiring-pool";
import type { Query as JobAlternativesQuery, Response as JobAlternativesResponse } from "../schemas/job/alternatives";
import type { Query as JobAutocompleteQuery, Response as JobAutocompleteResponse } from "../schemas/job/autocomplete";
import type { MissionTableQueryParams, MissionTableRow } from "../schemas/mission/mission";
import type {
  Query as PappersAutocompleteQuery,
  Response as PappersAutocompleteResponse,
} from "../schemas/pappers/autocomplete";
import type {
  Query as PappersEntrepriseQuery,
  Response as PappersEntrepriseResponse,
} from "../schemas/pappers/entreprise";
import type { PpeRequestEmail } from "../schemas/ppe-requests";
import type { RibCreateFromBoBody, RibCreateFromBoResponse } from "../schemas/rib";
import type {
  GetTalentShareAnonymousResumeLinkQuery,
  GetTalentShareAnonymousResumeLinkResponse,
  SendAnonymousResumeEmailBody,
  SendAnonymousResumeEmailResponse,
} from "../schemas/talent-share";
import type { ListDuplicatesBody, ListDuplicatesResponse } from "../schemas/talent/list-duplicates";
import type { GetTalentSummaryQuery, TalentSummaryResponse } from "../schemas/talent/summary";
import type { Body as TalentSuperSearchBody, Result as TalentSuperSearchResult } from "../schemas/talent/super-search";
import type {
  GenerateProfileSummaryBody,
  GenerateProfileSummaryResponse,
  TalentAnonymousBioQueryParams,
  TalentAnonymousBioResponse,
} from "../schemas/talent/talent";
import type { WorkflowUpsertTalentBody, WorkflowUpsertTalentResponse } from "../schemas/workflow";
import type { WorkspaceTransferBodySchema } from "../schemas/workspace";
import type {
  ContractSpecificityUpsertBody,
  ContractSpecificityUpsertResponse,
  ContractsTableQueryParams,
  ContractTableRow,
  PayTrackingCancelPayload,
  PayTrackingDetails,
  PayTrackingsTableQueryParams,
  PayTrackingTableRow,
} from "../types";
import type {
  DocusealArchiveAnswer,
  DocusealArchivePayload,
  DocusealSendAnswer,
  DocusealSendPayload,
  DocusealTemplateAnswer,
  DocusealTemplatePayload,
} from "../types/docuseal";
import type {
  MedicalCheckupRequestApproveBody,
  MedicalCheckupRequestApproveResponse,
} from "../types/medical-checkup-request";
import type {
  NylasAuthResponse,
  NylasCreateGrantRequest,
  NylasGetMessagesResponse,
  NylasGetThreadsResponse,
} from "../types/nylas";
import type { CursorPagination } from "../types/pagination";
import type { PosthogRequest } from "../types/posthog";
import type { QualificationFormQuestions } from "../types/qualification-form";
import type { SubstituteBenchTableQueryParams, SubstituteBenchTableRow } from "../types/substitute-bench";
import type { TalentTableRow } from "../types/talent";
import type { EmailThread } from "../utils/supabase.types";

export type ApiEndpoint =
  | {
      path: "timesheet/reset-signatures";
      method: "POST";
      body: TimesheetResetBody;
      response: TimesheetResetResponse;
    }
  | {
      path: "job-posting";
      method: "GET";
      query: JobPostingQuery;
      response: GetJobPostingResponse;
    }
  | {
      path: "job-posting";
      method: "POST";
      query: JobPostingQuery;
      response: CreateJobOfferResponse;
    }
  | {
      path: "job-posting";
      method: "PUT";
      query: JobPostingQuery;
      response: UpdateJobOfferResponse;
    }
  | {
      path: "job-posting";
      method: "DELETE";
      query: JobPostingQuery;
      response: DeleteJobOfferResponse;
    }
  | {
      path: "team-member/absence";
      method: "POST";
      body: TeamMemberAbsenceBody;
      response: TeamMemberAbsenceResponse;
    }
  | {
      path: "team-member/revert-absence";
      method: "POST";
      body: TeamMemberRevertAbsenceBody;
      response: TeamMemberAbsenceResponse;
    }
  | {
      path: "team-member/link-slack";
      method: "PUT";
      response: TeamMemberLinkSlackResponse;
    }
  | {
      path: "team-member/offboarding";
      method: "GET";
      query: TeamMemberOffboardingStatusQuery;
      response: TeamMemberOffboardingStatusResponse;
    }
  | {
      path: "team-member/offboarding";
      method: "PUT";
      body: TeamMemberOffboardingBody;
      response: TeamMemberOffboardingStatusResponse;
    }
  | {
      path: "qualification_training_request/:id/approve";
      method: "PUT";
      body: QualificationTrainingRequestApproveQuery;
      response: QualificationTrainingRequestApproveResponse;
      params: { id: string };
    }
  | {
      path: "navbar-count";
      method: "GET";
      response: NavButtonCount;
    }
  | {
      path: "document/send/contact";
      method: "POST";
      body: DocumentSenderContactBody;
      response: { success: boolean };
    }
  | {
      path: "document/send/talent";
      method: "POST";
      body: DocumentSenderTalentBody;
      response: { success: boolean };
    }
  | {
      path: "email/send_html";
      method: "POST";
      body: HtmlEmailSenderBody;
      response: { success: boolean };
    }
  | {
      path: "contract/notify-talent-contract-to-sign";
      method: "POST";
      body: TalentContractToSignNotificationBody;
      response: { success: boolean };
    }
  | {
      path: "talent/super-search";
      method: "POST";
      body: TalentSuperSearchBody;
      response: TalentSuperSearchResult;
    }
  | {
      path: "search/mission-name";
      method: "POST";
      body: SearchByMissionNameBody;
      response: { missionId: string }[];
    }
  | {
      path: "sms";
      method: "POST";
      body: SmsSenderBody;
      response: { success: boolean };
    }
  | {
      path: "workflows/upsert-talent";
      method: "POST";
      body: WorkflowUpsertTalentBody;
      response: WorkflowUpsertTalentResponse;
    }
  | {
      path: "talent/list-duplicates";
      method: "POST";
      body: ListDuplicatesBody;
      response: ListDuplicatesResponse;
    }
  | {
      path: "medical-checkup-request/send-medispace-email";
      method: "POST";
      body: MedispaceEmailSenderBody;
      response: { success: boolean };
    }
  | {
      path: "medical-checkup-request/:id/approve";
      method: "PUT";
      body: MedicalCheckupRequestApproveBody;
      response: MedicalCheckupRequestApproveResponse;
      params: { id: string };
    }
  | {
      path: "analytics/track-event";
      method: "POST";
      body: PosthogRequest;
      response: { success: boolean };
    }
  | {
      path: "analytics/talent/identify";
      method: "POST";
      body: AnalyticsTalentIdentifyBody;
      response: AnalyticsTalentIdentifyResponse;
    }
  | {
      path: "workspace/transfer";
      method: "POST";
      body: WorkspaceTransferBodySchema;
      response: { success: boolean };
    }
  | {
      path: "job/autocomplete";
      method: "GET";
      query: JobAutocompleteQuery;
      response: JobAutocompleteResponse;
    }
  | {
      path: "job/alternatives";
      method: "GET";
      query: JobAlternativesQuery;
      response: JobAlternativesResponse;
    }
  | {
      path: "talent-share/:id/anonymous-resume-link";
      method: "GET";
      query: GetTalentShareAnonymousResumeLinkQuery;
      response: GetTalentShareAnonymousResumeLinkResponse;
      params: { id: string };
    }
  | {
      path: "public/qualification-form";
      method: "GET";
      query: { token: string };
      headers: {
        "Content-Type": "application/json";
        Authorization: `Bearer ${string}`;
      };
      response: QualificationFormQuestions;
    }
  | {
      path: "contract-coefficients/list";
      method: "POST";
      body: ContractCoefficientsListParams;
      response: ContractCoefficientsResult;
    }
  | {
      path: "talent/:id/summary";
      method: "GET";
      query: GetTalentSummaryQuery;
      response: TalentSummaryResponse;
      params: { id: string };
    }
  | {
      path: "company/autocomplete";
      method: "GET";
      query: CompanyAutocompleteQuery;
      response: CompanyAutocompleteResponse;
    }
  | {
      path: "pappers/autocomplete";
      method: "GET";
      query: PappersAutocompleteQuery;
      response: PappersAutocompleteResponse;
    }
  | {
      path: "pappers/entreprise";
      method: "GET";
      query: PappersEntrepriseQuery;
      response: PappersEntrepriseResponse;
    }
  | {
      path: "talent-share/send-resumes";
      method: "POST";
      body: SendAnonymousResumeEmailBody;
      response: SendAnonymousResumeEmailResponse;
    }
  | {
      path: "feature-flag/posthog";
      method: "GET";
      query: FeatureFlagQuery;
      response: FeatureFlagResponse;
    }
  | {
      path: "contracts/table";
      method: "GET";
      query: ContractsTableQueryParams & CursorPagination;
      response: ContractTableRow[];
    }
  | {
      path: "docuseal/generate-template";
      method: "POST";
      body: DocusealTemplatePayload;
      response: DocusealTemplateAnswer;
    }
  | {
      path: "docuseal/send";
      method: "POST";
      body: DocusealSendPayload;
      response: DocusealSendAnswer;
    }
  | {
      path: "docuseal/archive";
      method: "POST";
      body: DocusealArchivePayload;
      response: DocusealArchiveAnswer;
    }
  | {
      path: "contract-specificity/upsert";
      method: "POST";
      body: ContractSpecificityUpsertBody;
      response: ContractSpecificityUpsertResponse;
    }
  | {
      path: "pay-trackings/table";
      method: "GET";
      query: PayTrackingsTableQueryParams;
      response: PayTrackingTableRow[];
    }
  | {
      path: "pay-tracking/:id";
      method: "GET";
      params: { id: string };
      response: PayTrackingDetails;
    }
  | {
      path: "pay-tracking/:id/cancel";
      method: "PUT";
      body: PayTrackingCancelPayload;
      response: { success: boolean };
      params: { id: string };
    }
  | {
      path: "timesheet/send-to-client";
      method: "POST";
      body: TimesheetSendToClientBody;
      response: TimesheetSendToClientResponse;
    }
  | {
      path: "talents/table";
      method: "GET";
      query: CursorPagination;
      response: TalentTableRow[];
    }
  | {
      path: "file/:id/parse-resume-contact-details";
      method: "GET";
      params: FileParseParamsType;
      response: FileParseResponseType;
    }
  | {
      path: "file/orphans";
      method: "GET";
      params: undefined;
      response: FileOrphanResponseType;
    }
  | {
      path: "talent/:talentId/generate-profile-summary";
      method: "POST";
      body: GenerateProfileSummaryBody;
      response: GenerateProfileSummaryResponse;
      params: { talentId: string };
    }
  | {
      path: "ppe-request/send-emails";
      method: "POST";
      body: PpeRequestEmail;
      response: { success: boolean };
    }
  | {
      path: "talent/:talentId/anonymous-bio";
      method: "GET";
      params: TalentAnonymousBioQueryParams;
      response: TalentAnonymousBioResponse;
    }
  | {
      path: "analytics/talent/track-event";
      method: "POST";
      body: AnalyticsTalentTrackEventBody;
      response: AnalyticsTalentTrackEventResponse;
    }
  | {
      path: "hiring-pools/table";
      method: "GET";
      query: HiringPoolTableQueryParams & CursorPagination;
      response: HiringPoolTableRow[];
    }
  | {
      path: "file/:id/parse-rib-iban";
      method: "GET";
      params: FileParseParamsType;
      response: FileParseRibIbanResponseType;
    }
  | {
      path: "substitute-bench/view";
      method: "GET";
      query: SubstituteBenchTableQueryParams & CursorPagination;
      response: SubstituteBenchTableRow[];
    }
  | {
      path: "substitute-bench/total-count";
      method: "GET";
      response: number;
    }
  | {
      path: "rib/create-from-bo";
      method: "POST";
      body: RibCreateFromBoBody;
      response: RibCreateFromBoResponse;
    }
  | {
      path: "nylas/auth";
      method: "GET";
      response: NylasAuthResponse;
    }
  | {
      path: "nylas/grant";
      method: "POST";
      body: NylasCreateGrantRequest;
      response: { success: boolean };
    }
  | {
      path: "nylas/grant";
      method: "GET";
      response: { success: boolean };
    }
  | {
      path: "nylas/threads";
      method: "GET";
      query: { search?: string; cursor?: string };
      response: NylasGetThreadsResponse;
    }
  | {
      path: "nylas/contact/:contactId/threads";
      method: "GET";
      params: { contactId: string };
      response: EmailThread[];
    }
  | {
      path: "nylas/threads/:threadId/messages";
      method: "GET";
      params: { threadId: string };
      response: NylasGetMessagesResponse;
    }
  | {
      path: "nylas/company/:companyId/threads";
      method: "GET";
      params: { companyId: string };
      response: EmailThread[];
    }
  | {
      path: "missions/table";
      method: "GET";
      query: MissionTableQueryParams & CursorPagination;
      response: MissionTableRow[];
    }
  | {
      path: "missions/:missionId/on-mission-created";
      method: "POST";
      params: { missionId: string };
      response: { success: boolean };
    }
  | {
      path: "contract/:contractId/update-contact-and-company-status-on-contract-signed";
      method: "POST";
      params: { contractId: string };
      response: { success: boolean };
    };

export type ApiEndpointMethod<Path extends ApiEndpoint["path"]> = Extract<ApiEndpoint, { path: Path }>["method"];
export type ApiEndpointResponse<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> = Extract<
  ApiEndpoint,
  { path: Path; method: Method }
>["response"];
export type ApiEndpointBody<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> =
  Extract<ApiEndpoint, { path: Path; method: Method }> extends { body: infer Body } ? Body : undefined;
export type ApiEndpointQuery<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> =
  Extract<ApiEndpoint, { path: Path; method: Method }> extends { query: infer Query } ? Query : undefined;

// Ensure params values are strings
type StringifyParams<T> = {
  [K in keyof T]: string;
};

export type ApiEndpointParams<Path extends ApiEndpoint["path"], Method extends ApiEndpoint["method"]> =
  Extract<ApiEndpoint, { path: Path; method: Method }> extends { params: infer Params }
    ? StringifyParams<Params>
    : undefined;

export type ApiEndpointOutput<
  Path extends ApiEndpoint["path"],
  Method extends ApiEndpoint["method"] = ApiEndpointMethod<Path>,
  Data = ApiEndpointResponse<Path, Method>,
> =
  | {
      success: true;
      data: Data;
    }
  | { success: false; error: ErrorSerialized };

export type ApiInvokeOptions<
  Path extends ApiEndpoint["path"],
  Method extends ApiEndpoint["method"] = ApiEndpointMethod<Path>,
> = {
  path: Path;
  method: Method;
  body?: ApiEndpointBody<Path, Method>;
  query?: ApiEndpointQuery<Path, Method>;
  params?: ApiEndpointParams<Path, Method>;
  maybeNull?: boolean;
  signal?: AbortSignal;
  headers?: HeadersInit;
};

export class ApiClient {
  constructor(
    private readonly params: {
      apiUrl: string;
      accessToken: string;
      logger: LoggerService;
    }
  ) {}

  public invoke = async <
    Path extends ApiEndpoint["path"],
    Method extends ApiEndpoint["method"] = ApiEndpointMethod<Path>,
    Data = ApiEndpointResponse<Path, Method>,
  >(
    options: ApiInvokeOptions<Path, Method>
  ): Promise<ApiEndpointOutput<Path, Method, Data>> => {
    try {
      const { apiUrl, accessToken, logger } = this.params;

      if (!apiUrl) throw new SharedError("[ApiClient] Missing API URL");
      if (!accessToken) throw new SharedError("[ApiClient] Missing access token");

      let url = `${apiUrl}/${options.path}`;
      if (options.query) {
        // We need to filter out undefined values because zod will sometimes return them
        const filteredQuery = Object.fromEntries(
          Object.entries(options.query).filter(([_, value]) => value !== undefined)
        ) as Record<string, string>;
        url += `?${new URLSearchParams(filteredQuery)}`;
      }
      if (options.params) {
        Object.entries(options.params).forEach(([key, value]) => {
          url = url.replace(new RegExp(`:${key}`, "g"), value as string);
        });
      }

      const headers: HeadersInit = {
        Authorization: `Bearer ${accessToken}`,
      };
      if ((options.method === "POST" || options.method === "PUT") && options.body)
        headers["Content-Type"] = "application/json";

      const response = await fetch(url, {
        method: options.method,
        headers,
        signal: options.signal,
        body: options.body ? JSON.stringify(options.body) : undefined,
      });

      if (!response.ok) {
        const data = (await response.json()) as ErrorSerialized;
        logger.error(data);
        throw new Error(data.message);
      }

      const data = (await response.json()) as Data;
      if (!options.maybeNull && !data) throw new Error("No data returned");

      return { success: true, data };
    } catch (exception) {
      this.params.logger.error(exception);

      return {
        success: false,
        error: {
          message: getErrorMessageForClient(exception),
        },
      };
    }
  };
}
