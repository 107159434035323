export type ErrorSerialized = {
  message: string;
  clientMessage?: string;
};

export interface SharedErrorOptions {
  status?: number;
  context?: Record<PropertyKey, unknown>;
  clientMessage?: string;
  isSentryIgnored?: boolean;
}

/**
 * Options for configuring a SharedError.
 *
 * @property message - Error message. Used for internal logging.
 * @property status - HTML status code sent to client. Defaults to 500.
 * @property context - Context to be sent to Sentry.
 * @property clientMessage - Message to be sent to the client. Defaults to "An error has occurred. Please try again later.".
 */
export class SharedError extends Error {
  public status?: number;
  public clientMessage?: string;
  public context?: Record<PropertyKey, unknown>;
  public isSentryIgnored?: boolean;

  constructor(
    message: string,
    { status, context, clientMessage, isSentryIgnored }: SharedErrorOptions = {
      status: 500,
      isSentryIgnored: false,
      clientMessage: "An error has occurred. Please try again later.",
    }
  ) {
    super(message);
    this.status = status;
    this.context = context;
    this.clientMessage = clientMessage;
    this.isSentryIgnored = isSentryIgnored;
  }
}

export function getErrorMessageForClient(error: unknown) {
  let message = "Unknown error";

  // First is for SerializedError
  if (
    error !== null &&
    typeof error === "object" &&
    "clientMessage" in error &&
    typeof error.clientMessage === "string"
  ) {
    message = error.clientMessage;
  }
  // Second is for SerializedError, Error, etc.
  else if (error !== null && typeof error === "object" && "message" in error && typeof error.message === "string") {
    message = error.message;
  } else if (typeof error === "string") {
    message = error;
  }

  return message;
}
